<template>
  <v-card outlined :disabled="loading" :loading="loading">
    <v-card-title> اضافة نقطة بيع جديدة </v-card-title>
    <v-card-text>
      <v-form :disabled="loading" @submit.prevent="addSale" ref="addSale" v-model="vaild">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field hide-detials="auto"
              label="اسم نقطة البيع"
              v-model="inputs.title"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              :items="companies"
              v-model="inputs.companyId"
              item-text="name"
              item-value="id"
              outlined
              label="الشركة"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              :items="provinecs"
              v-model="provinecId"
              item-text="name"
              item-value="id"
              outlined
              label="المحافظة"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              :items="regions"
              v-model="inputs.regionId"
              item-text="name"
              item-value="id"
              outlined
              label="المنطقة"
            ></v-select>
          </v-col>
          <v-col cols="12" md="12">
            <p class="black--text font-weight-bold text-h6 mx-2">
              قم بتحديد الموقع على الخريطة
            </p>
            <div class="d-flex justify-content-center align-items-center mt-1">
              <GmapMap
                ref="mapRef"
                :center="{ lat: 33.312805, lng: 44.361488 }"
                :zoom="12"
                @click="mark"
                map-type-id="terrain"
                style="width: 100%; height: 50vh"
              >
                <GmapMarker
                  ref="myMarker"
                  :position="google && new google.maps.LatLng(inputs.latitude, inputs.longtude)"
                />
              </GmapMap>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex justify-end mt-3">
          <v-btn type="submit" color="primary" large>Save</v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { gmapApi } from "vue2-google-maps";

export default {
  computed: {
    google: gmapApi,
  },
  methods:{
    mark(e){
      this.inputs.longtude = e.latLng.lng()
      this.inputs.latitude = e.latLng.lat()
    },
    addSale(){
      this.loading = true
      this.inputs.longtude = this.inputs.longtude.toString()
      this.inputs.latitude = this.inputs.latitude.toString()
      this.$http.post('/SalePoints/post',this.inputs).then(() => {
        this.$router.push({name:'dashboard-salepoint'})
      }).catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message)
          })
    }
  },
  data() {
    return {
      vaild: true,
      loading: true,
      provinecs: [],
      regions:[],
      provinecId:"",
      inputs: {
        companyId:"",
        title: "",
        longtude: 33.312805,
        latitude: 44.361488,
        regionId:"",
      },
    };
  },
  created() {
    this.loading = true;
    this.$http.get("/Provinecs").then((res) => {
      this.provinecs = res.data.result;
      this.$http.get("/Regions").then(reg => {
        this.regions = reg.data.data
        this.$http.get("/Companies",{
          params:{
            OfficeId: this.$store.state.userInfo.office.id
          }
        }).then((com) => {
          this.companies = com.data.result;
          this.loading = false;
          // this.checkIsEdit();
        });
      })
    
    }).catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message)
          })
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => {
      map.panTo({ lat: 33.312805, lng:  44.361488 });
    });
  },
};
</script>